import { createServer, Response } from "miragejs";

export function makeServer() {
  let server = createServer({
    routes() {
      this.namespace = "api";

      this.get("/patient/:mykad", (schema, request) => {
        const mykad = request.params.mykad;
        if (mykad === "910821035327") {
          return {
            mykad: "910821035327",
            name: "Ali bin Abu",
            diagnosis: "left inguinal hernia",
            operationDate: "09/09/2024",
          };
        } else {
          return new Response(404, {}, { error: "Patient not found" });
        }
      });
    },
  });

  return server;
}
