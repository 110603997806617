import React, { useState } from 'react';
import axios from 'axios';
import { Container, TextField, Button, Card, Typography, Alert } from '@mui/material';

function App() {
  const [mykad, setMykad] = useState('');
  const [patient, setPatient] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const handleSubmit = async () => {
    setNotFound(false); // Reset the not found flag
    try {
      const response = await axios.get(`/api/patient/${mykad}`);
      setPatient(response.data);
    } catch (error) {
      setNotFound(true);
    }
  };

  return (
    <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
      <Card variant="outlined" style={{ padding: '2rem' }}>
        <Typography variant="h6" gutterBottom>
          Masukkan Nombor MyKad
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          label="Nombor MyKad"
          value={mykad}
          onChange={(e) => setMykad(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          style={{ marginTop: '1rem' }}
          onClick={handleSubmit}
        >
          Hantar
        </Button>
        {patient && (
          <Card variant="outlined" style={{ marginTop: '2rem', padding: '1rem' }}>
            <Typography variant="subtitle1" gutterBottom>
              Maklumat Pesakit
            </Typography>
            <Typography variant="body2"><strong>Nama:</strong> {patient.name}</Typography>
            <Typography variant="body2"><strong>Diagnosis:</strong> {patient.diagnosis}</Typography>
            <Typography variant="body2"><strong>Tarikh Pembedahan:</strong> {patient.operationDate}</Typography>
          </Card>
        )}
        {notFound && (
          <Alert variant="outlined" severity="error" style={{ marginTop: '2rem' }}>
            Tiada maklumat pembedahan dijumpai
          </Alert>
        )}
      </Card>
    </Container>
  );
}

export default App;
